import React, { Component } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Styles } from "./styles/tabBox.js";
import franchise from "../assets/images/franchise.jpg";
import "./style.css"
import { colors } from "./common/element/elements.js";


class OnlineSupportBox extends Component {
  render() {
    const { heading } = this.props;

    return (
      <Styles>
        {/* Tab Box Area */}
        <section className="tab-section">
          <Container style={{ width: "50%", color: "black" }}>

            <h5 className="tab-title">Online Support for Students</h5>

            <p
              className="tab-desc"
              style={{ textAlign: "justify", fontSize: 14, marginTop: 10, color: colors.text2 }}
            >
              At Study Concerns International (SCI), we provide comprehensive Online Support Services to guide you throughout your academic journey. Our expert team is here to ensure your success with personalized assistance and free resources.            </p>
            <h6 className="tab-title" style={{ marginBlock: 10, color: "black" }}>What We Offer:</h6>
            <ul className="list-unstyled check-list tab-desc" style={{ color: colors.text2 }}>
              <li
                style={{
                  listStyleType: "disc",
                  marginLeft: "1em",
                  fontSize: 14,
                }}
              >
                <i style={{ marginTop: -5 }}></i>
                Personalized Guidance: Get help selecting the right academic program and institution.              </li>
              <li
                style={{
                  listStyleType: "disc",
                  marginLeft: "1em",
                  fontSize: 14,
                }}
              >
                <i style={{ marginTop: -5 }}></i>
                Free IELTS & Interview Preparation: Access free online IELTS training and interview coaching to boost your confidence.              </li>
              <li
                style={{
                  listStyleType: "disc",
                  marginLeft: "1em",
                  fontSize: 14,
                }}
              >
                <i style={{ marginTop: -5 }}></i>
                Application Assistance: Simplify your application process with our step-by-step guidance.              </li>
              <li
                style={{
                  listStyleType: "disc",
                  marginLeft: "1em",
                  fontSize: 14,
                }}
              >
                <i style={{ marginTop: -5 }}></i>
                Real-Time Support: Connect via Live Chat or WhatsApp +923222022121 for prompt assistance.              </li>
              <li
                style={{
                  listStyleType: "disc",
                  marginLeft: "1em",
                  fontSize: 14,
                }}
              >
                <i style={{ marginTop: -5 }}></i>
                Post-Admission Help: From travel to accommodation, we’re here even after you secure admission.              </li>

            </ul>
            <h6 className="tab-title" style={{ marginBlock: 10, color: "black" }}>Why Choose SCI?</h6>
            <ul className="list-unstyled check-list tab-desc" style={{ color: colors.text2 }}>
              <li
                style={{
                  listStyleType: "disc",
                  marginLeft: "1em",
                  fontSize: 14,
                }}
              >
                <i style={{ marginTop: -5 }}></i>
                With expert advice, free resources, and 24/7 online support, we’re committed to helping you achieve your goals.
              </li>

            </ul>
            <p
              className="tab-desc"
              style={{ textAlign: "justify", marginTop: 10, color: colors.text2 }}
            >
              Contact us at info@studyconcerns.com or WhatsApp +923222022121 to get started today!
            </p>
          </Container>
        </section>
      </Styles>
    );
  }
}

export default OnlineSupportBox;
