import React, { Component } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Styles } from "./styles/tabBox.js";
import franchise from "../assets/images/franchise.jpg";
import "./style.css"
import { colors } from "./common/element/elements.js";


class PrivacyPolicyBox extends Component {
  render() {
    const { heading } = this.props;

    return (
      <Styles>
        {/* Tab Box Area */}
        <section className="tab-section">
          <Container style={{ width: "50%", color: "black" }}>

            <h5 className="tab-title">Privacy Policys</h5>

            <p
              className="tab-desc"
              style={{ textAlign: "justify", fontSize: 14, marginTop: 10, color: colors.text2 }}
            >
At Study Concerns International (SCI), we prioritize the privacy and security of your personal information. Our services are designed to assist students in securing admissions to leading educational institutions. We solely collect and use personal data to provide personalized guidance, process applications, and enhance the user experience. SCI ensures that your information is handled with the utmost confidentiality and shared only with trusted educational institutions necessary for completing your admissions process. By using our website and services, you consent to this policy. Please contact us at info@studyconcerns.com for any questions or concerns.
              </p>
            
          </Container>
        </section>
      </Styles>
    );
  }
}

export default PrivacyPolicyBox;
