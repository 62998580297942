import React, { Component } from "react";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { Styles } from "./styles/tabBox.js";
import franchise from "../assets/images/franchise.jpg";
import "./style.css"
import { colors } from "./common/element/elements.js";


class GeneralBox extends Component {
  render() {
    const { heading } = this.props;

    return (
      <Styles>
        {/* Tab Box Area */}
        <section className="tab-section">
          <Container style={{ width: "50%", color: "black" }}>
          
            <h5 className="tab-title">{heading}</h5>
            
            <p
              className="tab-desc"
              style={{ textAlign: "justify", fontSize: 14, marginTop: 10, color:colors.text2 }}
            >
              Welcome to Study Concerns International (SCI), your trusted partner in achieving academic excellence and securing admissions to top-tier educational institutions worldwide. At SCI, we are committed to guiding students through every step of their educational journey, from selecting the right program to navigating the application process and ensuring a seamless transition to their desired institutions.
            </p>
            <h6 className="tab-title" style={{ marginBlock: 10, color: "black" }}>What We Do:</h6>
            <ul className="list-unstyled check-list tab-desc" style={{color:colors.text2}}>
              <li
                style={{
                  listStyleType: "disc",
                  marginLeft: "1em",
                  fontSize: 14,
                }}
              >
                <i style={{ marginTop: -5 }}></i>
                Admission Guidance: We assist students in identifying programs and universities that align with their academic goals and aspirations.
              </li>
              <li
                style={{
                  listStyleType: "disc",
                  marginLeft: "1em",
                  fontSize: 14,
                }}
              >
                <i style={{ marginTop: -5 }}></i>
                Application Support: Our team provides end-to-end support in preparing and submitting strong applications.
              </li>
              <li
                style={{
                  listStyleType: "disc",
                  marginLeft: "1em",
                  fontSize: 14,
                }}
              >
                <i style={{ marginTop: -5 }}></i>
                Career Counseling: We offer personalized counseling to help students choose the best academic pathways for their future careers.
              </li>
              <li
                style={{
                  listStyleType: "disc",
                  marginLeft: "1em",
                  fontSize: 14,
                }}
              >
                <i style={{ marginTop: -5 }}></i>
                Post-Admission Support: From travel arrangements to accommodation, we provide resources to make your transition smooth and stress-free.
              </li>
             
            </ul>
            <h6 className="tab-title" style={{ marginBlock: 10, color: "black" }}>Why Choose SCI?</h6>
            <ul className="list-unstyled check-list tab-desc" style={{color:colors.text2}}>
              <li
                style={{
                  listStyleType: "disc",
                  marginLeft: "1em",
                  fontSize: 14,
                }}
              >
                <i style={{ marginTop: -5 }}></i>
                Expert Team: Our experienced counselors are dedicated to understanding your unique needs and providing tailored solutions.              </li>
              <li
                style={{
                  listStyleType: "disc",
                  marginLeft: "1em",
                  fontSize: 14,
                }}
              >
                <i style={{ marginTop: -5 }}></i>
                Global Reach: We have partnerships with leading institutions around the world to open doors to exceptional opportunities.              </li>
              <li
                style={{
                  listStyleType: "disc",
                  marginLeft: "1em",
                  fontSize: 14,
                }}
              >
                <i style={{ marginTop: -5 }}></i>
                Personalized Service: At SCI, we prioritize individual attention to ensure your success.              </li>
              <li
                style={{
                  listStyleType: "disc",
                  marginLeft: "1em",
                  fontSize: 14,
                }}
              >
                <i style={{ marginTop: -5 }}></i>
                Commitment to Excellence: We are driven by a passion for education and a commitment to helping students achieve their dreams.              </li>
             
            </ul>
            <p
              className="tab-desc"
              style={{ textAlign: "justify", marginTop: 10, color:colors.text2 }}
            >
              For more information or to schedule a consultation, please contact us at info@studyconcerns.com. Together, let’s turn your academic aspirations into reality!
            </p>
          </Container>
        </section>
      </Styles>
    );
  }
}

export default GeneralBox;
